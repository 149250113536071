import { defineNuxtPlugin } from '#app/nuxt'
import { LazyLogoBambooHrGlyph, LazyLogoGoogleCalendarGlyph, LazyLogoGoogleGlyph, LazyLogoGoogleMailGlyph, LazyLogoGoogleMeetGlyph, LazyLogoHiBobGlyph, LazyLogoMattermostGlyph, LazyLogoMicrosoftGlyph, LazyLogoMicrosoftOutlookGlyph, LazyLogoMicrosoftTeamsGlyph, LazyLogoSalesforceGlyph, LazyLogoSlackGlyph, LazyLogoWorkdayGlyph, LazyLogoZoomGlyph, LazyIconBenchmark, LazyIconBox, LazyIconDollar, LazyIconHeatmap, LazyIconHeliopolis, LazyIconKings, LazyIconLineChart, LazyIconPhilae, LazyIconPyramid, LazyIconSort, LazyIconSphinx, LazyIconStarRating, LazyIconTemples, LazyIconUserProfile, LazyIcon, LazyIconCSS } from '#components'
const lazyGlobalComponents = [
  ["LogoBambooHrGlyph", LazyLogoBambooHrGlyph],
["LogoGoogleCalendarGlyph", LazyLogoGoogleCalendarGlyph],
["LogoGoogleGlyph", LazyLogoGoogleGlyph],
["LogoGoogleMailGlyph", LazyLogoGoogleMailGlyph],
["LogoGoogleMeetGlyph", LazyLogoGoogleMeetGlyph],
["LogoHiBobGlyph", LazyLogoHiBobGlyph],
["LogoMattermostGlyph", LazyLogoMattermostGlyph],
["LogoMicrosoftGlyph", LazyLogoMicrosoftGlyph],
["LogoMicrosoftOutlookGlyph", LazyLogoMicrosoftOutlookGlyph],
["LogoMicrosoftTeamsGlyph", LazyLogoMicrosoftTeamsGlyph],
["LogoSalesforceGlyph", LazyLogoSalesforceGlyph],
["LogoSlackGlyph", LazyLogoSlackGlyph],
["LogoWorkdayGlyph", LazyLogoWorkdayGlyph],
["LogoZoomGlyph", LazyLogoZoomGlyph],
["IconBenchmark", LazyIconBenchmark],
["IconBox", LazyIconBox],
["IconDollar", LazyIconDollar],
["IconHeatmap", LazyIconHeatmap],
["IconHeliopolis", LazyIconHeliopolis],
["IconKings", LazyIconKings],
["IconLineChart", LazyIconLineChart],
["IconPhilae", LazyIconPhilae],
["IconPyramid", LazyIconPyramid],
["IconSort", LazyIconSort],
["IconSphinx", LazyIconSphinx],
["IconStarRating", LazyIconStarRating],
["IconTemples", LazyIconTemples],
["IconUserProfile", LazyIconUserProfile],
["Icon", LazyIcon],
["IconCSS", LazyIconCSS],
  
]

export default defineNuxtPlugin({
  name: 'nuxt:global-components',
  setup (nuxtApp) {
    for (const [name, component] of lazyGlobalComponents) {
      nuxtApp.vueApp.component(name, component)
      nuxtApp.vueApp.component('Lazy' + name, component)
    }
  }
})
